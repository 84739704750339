import { useCallback, useEffect, useState } from 'react'

import TreasureChest from './treasure-chess.svg'

const TreasureChessLetter = () => {
  //
  const [open, _setOpen] = useState()

  const setOpen = useCallback(
    (open) => {
      document.body.style.overflow = open ? 'hidden' : ''
      document.body.style.paddingRight = open ? '17px' : ''

      _setOpen(open)
    },
    [_setOpen]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setOpen(true), [])

  return (
    <>
      {open && (
        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div
            aria-hidden="true"
            className="fixed inset-0 bg-gray-900 bg-opacity-80 pointer-events-none"
            style={{ backdropFilter: 'blur(6px)' }}
          />
          <div className="relative z-30 w-full max-w-2xl p-6 pointer-events-none sm:p-0 sm:my-8 sm:mx-auto overflow-hidden">
            <div className="bg-white flex flex-col p-8 rounded space-y-4 w-full pointer-events-auto">
              <TreasureChest
                className="text-gray-900 mx-auto"
                style={{ maxWidth: '200px' }}
              />
              <p>Dear Nifty Chess Friends,</p>
              <p>
                We have some exciting news to share with you! We have been
                working very hard over the past year with our new partner,
                Chess.com to bring an upgraded iteration of Nifty Chess:
                Treasure Chess.
              </p>
              <p>
                <a
                  className="text-pink-600 hover:text-pink-800 hover:underline"
                  href="https://treasure.chess.com"
                >
                  Treasure Chess
                </a>{' '}
                honors and greatly expands on our initial aim of providing a fun
                and non-extractive platform that adds value to chess players
                interested in NFTs. This commitment has led us to a unique
                opportunity to partner with Chess.com to create a frictionless
                onboarding experience to crypto for millions of chess players.
              </p>
              <p>
                Thank you so much for participating in the world of Nifty Chess
                over the past year! It has been very rewarding to meet so many
                people who are passionate about chess and crypto. We’ve learned
                a lot through building Nifty Chess and sharing it with all of
                you. One thing we learned is that we were never able to verify
                that a match had been minted by the person who had actually
                played that game. We wanted our users to be able to prove an NFT
                was minted by a particular streamer, or a player really achieved
                some cool feat they are proud of –– this led us to make the
                decision to build Treasure Chess, in partnership with Chess.com.
              </p>
              <p>Treasure Chess will let all Chess.com users:</p>
              <ul className="list-inside list-disc space-y-2 px-4">
                <li>
                  Log in with their existing Chess.com account and be given a
                  secure Ethereum wallet without needing to use MetaMask or have
                  any previous experience with web3.
                </li>
                <li>
                  Mint any match verifiably played on Chess.com as an NFT that
                  has achievements and a corresponding level based on those
                  achievements.
                </li>
                <li>
                  Access a direct fiat onramp with debit cards and mint freely
                  by default.
                </li>
                <li>
                  Not only purchase NFTs, but earn in a marketplace through
                  collecting and selling Treasures.
                </li>
              </ul>
              <p>
                <em>
                  If you’ve ever minted a game on Nifty Chess, those NFTs will
                  still exist on Gnosis Chain. However, none of those games will
                  carry over into Treasure Chess, as they exist on different
                  networks and the structure of the NFTs have now evolved to
                  offer more interactivity.
                </em>
              </p>
              <p>
                We are so excited to share this next chapter with all of you!
                Thank you so much for joining us on this adventure — let’s keep
                building and playing together.
              </p>
              <p>Looking forward,</p>
              <p>
                Joseph Schiarizzi
                <br />
                Founder, Nifty Chess
                <br />
                <a
                  className="text-pink-600 hover:text-pink-800 hover:underline"
                  href="https://treasure.chess.com"
                >
                  https://treasure.chess.com
                </a>
              </p>
              <button
                className="self-end inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-purple hover:bg-purple-dark"
                onClick={() => setOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="text-white bg-gradient-to-r from-purple to-pink p-4 w-full shadow sticky top-0 z-10">
        <div className="flex flex-col md:flex-row items-center md:justify-between max-w-7xl mx-auto px-4 sm:px-6 space-y-4 md:space-y-0">
          <p className="text-lg text-center md:text-left font-semibold">
            NiftyChess is now{' '}
            <a className="underline" href="https://treasure.chess.com">
              Treasure Chess!
            </a>
          </p>
          <button
            className="hover:underline bg-white text-gray-800 font-bold rounded py-2 px-4"
            onClick={() => setOpen(true)}
          >
            Read More!
          </button>
        </div>
      </div>
    </>
  )
}

export default TreasureChessLetter
